// https://github.com/chakra-ui/chakra-ui/blob/d1c8f1ada108495c56e50766730352a6cfb642e7/packages/modal/src/modal-transition.tsx
import { scaleFadeConfig } from '@transitions/scale-fade';
import { HTMLMotionProps, motion } from 'framer-motion';
import * as React from 'react';

export interface ModalTransitionProps extends Omit<HTMLMotionProps<'section'>, 'color'> {
	preset: 'scale' | 'none';
}

const transitions = {
	// slideInBottom: {
	//   ...slideFadeConfig,
	//   custom: { offsetY: 16, reverse: true },
	// },
	// slideInRight: {
	//   ...slideFadeConfig,
	//   custom: { offsetX: 16, reverse: true },
	// },
	scale: {
		...scaleFadeConfig,
		custom: { initialScale: 0.95, reverse: true },
	},
	none: {},
};

export const ModalTransition = React.forwardRef(
	(props: ModalTransitionProps, ref: React.Ref<any>) => {
		const { preset, ...rest } = props;
		const motionProps = transitions[preset];
		return <motion.section ref={ref} {...motionProps} {...rest} />;
	},
);

ModalTransition.displayName = 'ModalTransition';
