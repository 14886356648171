import { Portal } from '@chakra-ui/portal';
import React from 'react';

const defaultModalContext = {
	isOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onClose: () => {},

	blockScrollOnMount: true,
	closeOnEsc: true,
};

export type ModalContextType = {
	isOpen: boolean;
	onClose: () => void;
	blockScrollOnMount?: boolean;
	closeOnEsc?: boolean;
};

export const ModalContext = React.createContext(defaultModalContext);

export interface ModalProps extends ModalContextType {}

const Modal: React.FC<ModalProps> = ({ children, ...context }) => {
	return (
		<Portal>
			<ModalContext.Provider
				value={{
					blockScrollOnMount: true,
					closeOnEsc: true,
					...context,
				}}
			>
				{children}
			</ModalContext.Provider>
		</Portal>
	);
};

export default Modal;
