import fadeConfig from '@transitions/fade-config';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext } from 'react';
import { ModalContext } from './Modal';

export interface ModalOverlayProps {}

const ModalOverlay: React.FC<ModalOverlayProps> = () => {
	const { isOpen } = useContext(ModalContext);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div
					{...fadeConfig}
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						backgroundColor: '#000000cc',
						zIndex: 1400,
					}}
				/>
			)}
		</AnimatePresence>
	);
};

export default ModalOverlay;
