import React from 'react';
import ModalCloseButton from './ModalCloseButton';

export interface ModalHeaderProps {}

const ModalHeader: React.FC<ModalHeaderProps> = ({ children }) => {
	return (
		<div className="flex text-white bg-primary-500 px-2 py-3">
			<div className="px-3 py-5">{children}</div>
			{/* Spacing */}
			<div className="mx-auto" />
			<ModalCloseButton />
		</div>
	);
};

export default ModalHeader;
