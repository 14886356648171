import Page from '@components/Layout/Page';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@components/Modal';
import moment, { Moment } from 'moment';
import { toWords } from 'number-to-words';
import React, { useEffect, useState } from 'react';
import { Schedule } from '../../components/schedule';

export interface Event {
	title: string;
	// "1:00 PM"
	start: string;
	end: string;
	description: string;
	location: string;
	startTime: Moment;
	endTime: Moment;
}

export interface EventGroup {
	title: string;
	events: Event[];
}

export interface Days {
	[dayName: string]: EventGroup[];
}

const placeholderEvent: Event = {
	title: 'Loading...',
	start: '1:00 PM',
	end: '3:00 PM',
	description: 'Free',
	location: 'Idea Forge',
	startTime: moment('1:00 PM', 'h:mm a'),
	endTime: moment('3:00 PM', 'h:mm a'),
};

// todo: mobile view
// multiple days
export default () => {
	const [open, setOpen] = useState(false);
	const [items, setItems] = useState<Days>({
		day1: [
			{
				title: 'Idea Forge',
				events: [
					{
						title: 'Loading 1...',
						start: '1:00 PM',
						end: '3:00 PM',
						description: 'Free',
						location: 'Idea Forge',
						startTime: moment('1:00 PM', 'h:mm a'),
						endTime: moment('3:00 PM', 'h:mm a'),
					},
					{
						title: 'Loading 2...',
						start: '9:00 AM',
						end: '10:00 AM',
						description: 'Free',
						location: 'Idea Forge',
						startTime: moment('9:00 AM', 'h:mm a'),
						endTime: moment('10:00 AM', 'h:mm a'),
					},
					{
						title: 'Loading 3...',
						start: '3:00 PM',
						end: '4:00 PM',
						description: 'Free',
						location: 'Idea Forge',
						startTime: moment('3:00 PM', 'h:mm a'),
						endTime: moment('4:00 PM', 'h:mm a'),
					},
				],
			},
		],
	});
	const [current, setCurrent] = useState<Event>(placeholderEvent);
	const [currentDay, setCurrentDay] = useState('day1');
	const openItem = (item: Event) => {
		setCurrent(item);
		setOpen(true);
	};
	useEffect(() => {
		fetch('https://api.hackcu.org/sheets/events.json')
			.then((res) => res.json())
			.then((data) => {
				const days = {};
				for (const day of Object.keys(data)) {
					const locations = {};
					for (const item of data[day]) {
						const startTime = item.Start ? moment(item.Start, 'hh:mm a') : null;
						const endTime = item.End ? moment(item.End, 'hh:mm a') : null;

						if (!(item.Location in locations)) {
							locations[item.Location] = { title: item.Location, events: [] };
						}

						locations[item.Location].events.push({
							start: item.Start,
							end: item.End,
							title: item.Name,
							location: item.Location,
							description: item.Description,
							startTime,
							endTime,
						});
					}
					days[day] = Object.values(locations);
				}
				setItems(days);
				setCurrentDay(Object.keys(days)[0]);
			});
	}, []);

	/**
	 * Returns a human readable form of day key
	 * @param dayKey The day string in form of "day2"
	 */
	const dayToText = (dayKey: string): string => {
		const dayCount = parseInt(dayKey.replace('day', ''));
		const captalizedWords = toWords(dayCount).replace(/\b\w/g, (wordLetter) =>
			wordLetter.toUpperCase(),
		);
		return `Day ${captalizedWords}`;
	};

	return (
		<Page title="Live Schedule" live>
			<h1 className="font-bold text-5xl my-3">Schedule</h1>

			<Modal isOpen={open} onClose={() => setOpen(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<h2 className="font-bold text-2xl">{current.title}</h2>
						<p>
							{current.start}
							{current.end ? ` - ${current.end}` : ''}
						</p>
					</ModalHeader>

					<ModalBody>
						{current.description && (
							<p className="text-md">
								<b>Description:</b> {current.description}
							</p>
						)}
						{current.location && (
							<p className="mt-3 text-md">
								<b>Location:</b> {current.location}
							</p>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>

			<div className="mx-auto mb-4 flex flex-col">
				{/* TODO */}
				<h1 className="text-4xl font-bold text-center mx-auto">{dayToText(currentDay)}</h1>
				<select
					className="mx-auto block text-black bg-white rounded-md my-3"
					name="day"
					onChange={(event) => setCurrentDay(event.target.value)}
					aria-label="Select which day's schedule to see."
				>
					{Object.keys(items).map((day) => (
						<option key={day} value={day}>
							{dayToText(day)}
						</option>
					))}
				</select>
			</div>

			<div className="px-8">
				<Schedule autoTime events={items[currentDay]} hourHeight={75} openEvent={openItem} />
			</div>
		</Page>
	);
};
