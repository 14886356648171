import { AnimatePresence } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { ModalContext } from './Modal';
import { ModalTransition } from './modal-transition';

export interface ModalContentProps {}

const ModalContent: React.FC<ModalContentProps> = ({ children }) => {
	const { isOpen, onClose, blockScrollOnMount, closeOnEsc } = useContext(ModalContext);

	// Prevent scrolling
	useEffect(() => {
		if (blockScrollOnMount) {
			document.body.style.overflow = isOpen ? 'hidden' : 'initial';
		}
	}, [blockScrollOnMount, isOpen]);

	// Remove block scroll on unmount
	useEffect(() => {
		if (blockScrollOnMount) {
			return () => {
				document.body.style.overflow = 'initial';
			};
		}
	}, [blockScrollOnMount]);

	// close modal on escape button press
	useEffect(() => {
		const escFunction = (event: KeyboardEvent) => {
			switch (event.key) {
				case 'Esc':
				case 'Escape':
					onClose();
			}
		};

		if (closeOnEsc) {
			document.addEventListener('keydown', escFunction);
		}

		return () => document.removeEventListener('keydown', escFunction);
	}, [closeOnEsc]);

	return (
		<AnimatePresence>
			{isOpen && (
				<div
					className="flex fixed inset-0 justify-center items-start"
					style={{
						zIndex: 1400,
					}}
					onClick={onClose}
				>
					<ModalTransition
						preset="scale"
						onClick={(event) => {
							event.stopPropagation();
						}}
						className="bg-primary-500 overflow-auto w-full md:w-96 min-h-full md:min-h-0 md:rounded-md mt-0 md:mt-4"
						role="dialog"
						aria-modal
					>
						{children}
					</ModalTransition>
				</div>
			)}
		</AnimatePresence>
	);
};

export default ModalContent;
