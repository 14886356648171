import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import { Event, EventGroup } from '../pages/live/schedule';
import styles from './schedule.module.css';

interface Props {
	autoTime?: boolean;
	startTime?: number;
	endTime?: number;
	hourHeight?: number;
	events: EventGroup[];
	openEvent: (event: Event) => void;
}

export const Schedule: React.FC<Props> = ({
	autoTime = false, // Calulates the best start and end time to fit every item
	startTime = 8,
	endTime = 23,
	hourHeight = 200,
	events,
	openEvent,
}) => {
	// TODO: remove events that have already passed in time?
	const allEvents = events.reduce((prev, group) => [...prev, ...group.events], [] as Event[]);
	const minTime = Math.floor(
		allEvents.reduce((min, event) => {
			if (!!event.startTime && event.startTime.hours() * 60 + event.startTime.minutes() < min) {
				return event.startTime.hours() * 60 + event.startTime.minutes();
			}
			return min;
		}, 24 * 60) / 60,
	);
	const maxTime = Math.ceil(
		allEvents.reduce((max, event) => {
			if (!!event.endTime && event.endTime.hours() * 60 + event.endTime.minutes() > max) {
				return event.endTime.hours() * 60 + event.endTime.minutes();
			} else if (
				!!event.startTime &&
				event.startTime.hours() * 60 + event.startTime.minutes() + 60 > max
			) {
				// if no end time but there is a startime account space for one hour (to fit the element at the end)
				return event.startTime.hours() * 60 + event.startTime.minutes() + 60;
			}
			return max;
		}, 0) / 60,
	);

	const calStart = autoTime ? minTime : startTime;
	const calEnd = autoTime ? maxTime : endTime;
	const times = [];

	for (let i = calStart; i < calEnd; ++i) {
		times.push(`${i === 12 ? 12 : i % 12}:00${i < 12 ? 'AM' : 'PM'}`);
	}

	return (
		<div className="flex relative">
			{/* The hour lines */}
			<div className="flex flex-col w-0">
				<div style={{ height: hourHeight }} />
				{times.map((time) => (
					<div
						key={time}
						className={styles.line}
						style={{
							height: hourHeight,
						}}
					/>
				))}
			</div>

			{/* The times */}
			<div className="flex flex-col mx-1 items-end" style={{ zIndex: 1 }}>
				{/* for title */}
				<div style={{ height: hourHeight }} />
				{times.map((time) => (
					<div key={time} style={{ height: hourHeight }}>
						<p
							className="bg-background-500 text-right relative"
							style={{
								top: '-12px',
								right: '10px',
							}}
						>
							{time}
						</p>
					</div>
				))}
			</div>

			{/* The tabs with entries */}
			{events.map(({ title, events: eventsAtLocation }, index, types) => {
				return (
					<div
						key={title}
						className="mx-1 relative"
						style={{
							width: `${(1 / types.length) * 100}%`,
							height: times.length * hourHeight,
						}}
					>
						{/* Title */}
						<div className="text-center" style={{ height: hourHeight }}>
							<span style={{ overflow: 'overlay' }}>{title ? title : 'Misc.'}</span>
						</div>
						{/* Items */}
						{eventsAtLocation.map(({ title: eventTitle, startTime, endTime }, index, list) => {
							const top = startTime
								? (startTime.hours() + startTime.minutes() / 60 - calStart) * hourHeight +
								  hourHeight
								: hourHeight;
							// todo: height is not correct for 30 minute events
							const height =
								!!startTime && !!endTime
									? moment.duration(endTime.diff(startTime)).asHours() * hourHeight
									: hourHeight;
							return (
								<div
									className={clsx(
										'flex text-center rounded-sm absolute left-0 right-0 cursor-pointer',
										index % 2 === 0 ? 'bg-primary-500' : 'bg-secondary-500',
									)}
									key={eventTitle}
									// color="textDark"
									style={{
										top,
										height,
									}}
									onClick={() => openEvent(list[index])}
									tabIndex={0}
									aria-label={`Select to get details about ${eventTitle}.`}
								>
									<p
										className="overflow-hidden m-auto font-bold text-md sm:text-xl flex px-2"
										style={{ height }}
									>
										{/* multiline ellipsis */}
										<Dotdotdot clamp="auto" className="m-auto">
											{eventTitle}
										</Dotdotdot>
									</p>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};
