// From chakra ui:
// https://github.com/chakra-ui/chakra-ui/blob/f41a231aa7241419aa8bb4a31cca508e19a3a464/packages/transition/src/scale-fade.tsx
import { HTMLMotionProps } from 'framer-motion';
import { EASINGS, MotionVariants } from './utils';

type ScaleFadeVariants = MotionVariants<'enter' | 'exit'>;

const variants: ScaleFadeVariants = {
	exit: (props) => ({
		opacity: 0,
		...(props.reverse
			? { scale: props.initialScale }
			: { transitionEnd: { scale: props.initialScale } }),
		transition: {
			duration: 0.1,
			ease: EASINGS.easeOut,
		},
	}),
	enter: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.25,
			ease: EASINGS.easeInOut,
		},
	},
};

export const scaleFadeConfig: HTMLMotionProps<'div'> = {
	initial: 'exit',
	animate: 'enter',
	exit: 'exit',
	variants,
};
