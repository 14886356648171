import { HTMLMotionProps, Variant } from 'framer-motion';

// From chakra:
// https://github.com/chakra-ui/chakra-ui/blob/f41a231aa7241419aa8bb4a31cca508e19a3a464/packages/transition/src/fade.tsx

export type MotionVariants<T extends string> = Record<T, Variant>;

export const EASINGS = {
	ease: [0.25, 0.1, 0.25, 1],
	easeIn: [0.4, 0, 1, 1],
	easeOut: [0, 0, 0.2, 1],
	easeInOut: [0.4, 0, 0.2, 1],
};

const variants: MotionVariants<'enter' | 'exit'> = {
	exit: {
		opacity: 0,
		transition: {
			duration: 0.1,
			ease: EASINGS.easeOut,
		},
	},
	enter: {
		opacity: 1,
		transition: {
			duration: 0.2,
			ease: EASINGS.easeIn,
		},
	},
};

const fadeConfig: HTMLMotionProps<any> = {
	initial: 'exit',
	animate: 'enter',
	exit: 'exit',
	variants,
};

export default fadeConfig;
