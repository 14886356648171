import React, { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import { ModalContext } from './Modal';

export interface ModalCloseButtonProps {}

const ModalCloseButton: React.FC<ModalCloseButtonProps> = () => {
	const { onClose } = useContext(ModalContext);

	return (
		<div className="flex">
			<div
				className="ml-auto my-auto mr-3 text-xl cursor-pointer"
				onClick={onClose}
				tabIndex={0}
				aria-label="Close modal"
			>
				<FaTimes />
			</div>
		</div>
	);
};

export default ModalCloseButton;
